import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./new.css";
import logoImg from '../assest/images/rrrrrr_page-0001.jpg'
import { MdMenu } from "react-icons/md";

function Navbar() {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  const closeNav = () => setIsNavCollapsed(true);

  const location = useLocation();

  // Define a function to determine if a menu item is active
  const isMenuItemActive = (menuItemPath) => {
    // Compare the current location pathname with the menu item path
    return location.pathname === menuItemPath;
  };
  // console.log(isMenuItemActive("/"))
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-style shadow">
        <div className="container-fluid">
          <Link
            className="navbar-brand px-sm-0 px-md-5"
            to="/"
            style={{ fontWeight: "bold" }}
          >
            <img
              src={logoImg}
              alt=""
              className="imgeLogo"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded={!isNavCollapsed ? true : false}
            aria-label="Toggle navigation"
            onClick={handleNavCollapse}
          >
            <MdMenu className=" text-white fs-1" />
          </button>
          <div
            className={`collapse navbar-collapse ${isNavCollapsed ? "" : "show"
              }`}
            id="navbarNavDropdown"
          >
            <ul className="navbar-nav ps-5">
              <li className="nav-item">
                <Link
                  className={`nav-link animated fadeInRight  ${isMenuItemActive("/") ? "activenav" : ""
                    }`}
                  onClick={closeNav} // Assuming closeNav is a function to close the navbar
                  aria-current="page"
                  to="/"
                  style={{ animationDelay: ".3s", animateDuration: "700ms" }}
                >
                  HOME
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  className="nav-link animated fadeInRight text-nowrap"
                  onClick={closeNav}
                  to="/about"
                  style={{ animationDelay: ".4s", animateDuration: "700ms" }}
                >
                  ABOUT MIDTECH
                </Link>
              </li>

              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle animated fadeInRight"
                  to="/services_division"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ animationDelay: ".5s", animateDuration: "800ms" }}
                >
                  SERVICES DIVISION
                </Link>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <Link
                      to="/maintenance_services_division"
                      className="dropdown-item text-dark"
                      onClick={closeNav}
                    >
                      Maintenance Services Division
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/civil_infrastructure_services_division"
                      className="dropdown-item text-dark"
                      onClick={closeNav}
                    >
                      Civil and Infrastructure Division
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/major_projects_division"
                      className="dropdown-item text-dark"
                      onClick={closeNav}
                    >
                      Major Projects Division
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/support_services_division"
                      className="dropdown-item text-dark"
                      onClick={closeNav}
                    >
                      Support Services Division
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/offshore_services_division"
                      className="dropdown-item text-dark"
                      onClick={closeNav}
                    >
                      Offshore Services Division
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/electrical_instrumentation_division"
                      className="dropdown-item text-dark"
                      onClick={closeNav}
                    >
                      Electrical & Instrumentation (E&I) Division
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="nav-item">
                <Link
                  className="nav-link animated fadeInRight text-nowrap"
                  onClick={closeNav}
                  to="/projects"
                  style={{ animationDelay: ".6s", animateDuration: "800ms" }}
                >
                  PROJECTS
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  className="nav-link animated fadeInRight text-nowrap"
                  onClick={closeNav}
                  to="/ourpeople"
                  style={{ animationDelay: ".6s", animateDuration: "800ms" }}
                >
                  OUR TEAM
                </Link>
              </li>

              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle animated fadeInRight"
                  to="/imagegallery"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ animationDelay: ".5s", animateDuration: "800ms" }}
                >
                  GALLERY
                </Link>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <Link
                      to="/imagegallery"
                      className="dropdown-item text-dark"
                      onClick={closeNav}
                    >
                      Image Gallery
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/videogallery"
                      className="dropdown-item text-dark"
                      onClick={closeNav}
                    >
                      Video Gallery
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="nav-item">
                <Link
                  className="nav-link animated fadeInRight"
                  onClick={closeNav}
                  to="/career"
                  style={{ animationDelay: ".7s", animateDuration: "800ms" }}
                >
                  CAREER
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link animated fadeInRight"
                  onClick={closeNav}
                  to="/blogs"
                  style={{ animationDelay: ".8s", animateDuration: "800ms" }}
                >
                  BLOGS
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link animated fadeInRight"
                  onClick={closeNav}
                  to="/contact"
                  style={{ animationDelay: ".9s", animateDuration: "800ms" }}
                >
                  CONTACT
                </Link>
              </li>
            </ul>

          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
