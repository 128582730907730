
import img1 from '../assest/images/pexels-athena-3588036.jpg'
import { Link } from "react-router-dom";
import MaintenanceServicesData from "../data/MaintenanceServicesData";
import { Button } from '@mui/material';
import '../style/ProjectStyle.css'
import ProjectData from '../data/ProjectData';



function Projects() {

    return (
        <div>
            <section className="Projects">
                <div className="bg_image_1">
                    <div className="content">
                        <h3>Projects</h3>
                    </div>
                </div>
            </section>

            <section>
                <hr />
                <div className="container py-5">
                    <h1 className="">
                        Projects
                    </h1>
                    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 ">
                        {ProjectData.map((item) => {
                            return (
                                <div className="col py-4 DivisiondivProjects">
                                    <div
                                        className="card card-color mx-auto shadow p-2"
                                    >
                                        <img
                                            src={item.src}
                                            className="card-img-top"
                                            alt="..."
                                        />
                                        <div className="card-body">
                                            <h5 className="card-title">{item.heading}</h5>
                                            <Link> <Button>Read More</Button></Link>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>

        </div>
    );
}

export default Projects;
