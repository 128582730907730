import people1 from '../assest/images/SupportServicesDivision.jpg'
import people2 from '../assest/images/slider2.jpg'
import people3 from '../assest/images/slider3.jpg'
import people4 from '../assest/images/Maintenance_Services.jpg'
import people5 from '../assest/images/NewKidInTown1.jpg'
import people6 from '../assest/images/electrical_instrumentation.jpg'


const ServicesDivisionData = [
    {
        id: 1,
        heading: 'Maintenance Services Division',
        aboutInfo: 'Provision of Plant Maintenance Services that involves static & rotating equipment maintenance, repair & modification of piping ..',
        linkTag: 'maintenance_services_division',
        src: people4,
    },
    {
        id: 2,
        heading: " Civil and Infrastructure Division",
        aboutInfo: 'Midtech’s long experience which goes back to 1974 in buildings construction and interior finishing works has allowed us to improve ..',
        linkTag: 'civil_infrastructure_services_division',
        src: people2,
    },
    {
        id: 3,
        heading: "Major Projects Division",
        aboutInfo: 'Midtech is an integrated multi-disciplined Onshore and Offshore EPC/ EPIC & general contractor that has delivered EPC/EPIC and..',
        linkTag: 'major_projects_division',
        src: people3,
    },
    {
        id: 4,
        heading: "Support Services Division",
        aboutInfo: 'Midtech has an extensive fleet of plant and equipment, which includes cranes up to 300 tons capacity (and access to larger ones) ..',
        linkTag: 'support_services_division',
        src: people1,
    },
    {
        id: 5,
        heading: ' Offshore Services Division',
        aboutInfo: 'Midtech Marine Fabrication Yard has a wide experience in the field of Onshore Construction works of offshore fixed platforms for ..',
        linkTag: 'offshore_services_division',
        src: people5,
    },
    {
        id: 6,
        heading: ' Electrical & Instrumentation (E&I) Division',
        aboutInfo: 'Midtech Marine Fabrication Yard has a wide experience in the field of Onshore Construction works of offshore fixed platforms for ..',
        linkTag: 'electrical_instrumentation_division',
        src: people6,
    },
];
export default ServicesDivisionData;
