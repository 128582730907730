

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { MdOutlineMarkEmailRead } from "react-icons/md";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function ThankYouDialoge({ open, setOpen, handleClose }) {

    return (
        <>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >

                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'red',
                        fontWeight:'bolder'
                    })}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers className=' text-center p-5'>
                    <p><MdOutlineMarkEmailRead style={{fontSize:'5rem'}} className='text-success'/></p>
                    <h1 className=' fs-1 text-success'>Thanks for submitting!</h1>
                    <p className='text-success'> Your message has been sent!</p>
                    <Link to={'/'}><Button className=' bg-success fw-bold px-5' style={{ color: 'white', textTransform: 'capitalize' }}>Go Home</Button></Link>


                </DialogContent>
                <DialogActions>
                    <Button className=' text-danger fw-bold' autoFocus onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </>
    );
}