
import client1 from '../assest/images/certficate1.png'
import client2 from '../assest/images/certficate2.png'
import client3 from '../assest/images/certficate3.png'
import client4 from '../assest/images/certficate4.jpg'



const CertificateData = [
    {
        id: 1,
        imgSrc: client1,
        // name: 'Chennai client'
    },
    {
        id: 2,
        imgSrc: client2,
        // name: 'Delhi client'
    },
    {
        id: 3,
        imgSrc: client3,
        // name: 'Kochi client'

    }, {
        id: 4,
        imgSrc: client4,
        // name: 'NHAI Projects'
    }, {
        id: 5,
        imgSrc: client3,
        // name: 'Navi Mumbai Airport'

    }
    
];
export default CertificateData

