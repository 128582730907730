
import React from "react";
import { Link } from "react-router-dom";

function OffshoreServicesDivision() {
    return (
        <div className="">
            <di className="OffshoreServicesDivision">
                <div className="bg_image_1">
                    <div className="content">
                        <h3>Offshore & Marine Services Division</h3>
                    </div>

                </div>

                <section>
                    <div className="container py-5">

                        <div className="row justify-content-between py-5">
                            <h1 className="text-center pb-5">Offshore & Marine Services Division</h1>
                            <div className="col-12 col-md-6 ">
                                <div
                                    className="card mb-3 card-color shadow"
                                    style={{ borderRadius: "5px" }}
                                >
                                    <div className="card-body">
                                        <h5 className="card-title ">Offshore EPIC Projects</h5>
                                        <p className="card-text ">
                                            Midtech Marine Fabrication Yard has a wide experience in the field of Onshore Construction works of offshore fixed platforms for the Oil and Gas industries as well as Maintenance and repair works of all types of marine vessels like tug boats, cargo barges, accommodation and jack up marine units.
                                        </p>
                                    </div>
                                </div>

                                <div
                                    className="card mb-3 card-color shadow"
                                    style={{ borderRadius: "5px" }}
                                >
                                    <div className="card-body">
                                        <h5 className="card-title ">Marine & Offshore Logistics Support</h5>
                                        <p className="card-text ">
                                            Midtech Marine Fabrication Yard provides a wide range of integrated services in the following areas:
                                        </p>

                                        <ul>
                                            <li>Berthing facility for all types of marine vessels.</li>
                                            <li>Load out and sea fastening of heavy and large size structures.</li>
                                            <li>Construction Engineering works including shop drawings, heavy lift studies and load out procedures.</li>
                                            <li>Steel structural and piping prefabrication and construction works.</li>
                                            <li>Plates Forming works and supplementary Machining works.</li>
                                            <li>Onshore pre commissioning works.</li>
                                            <li>Blasting and painting works.</li>
                                            <li>QC, Inspection and NDT tests.</li>
                                            <li>Scaffolding works.</li>
                                            <li>Hydro testing and Leak tests.</li>
                                            <li>Multi qualifications and welding procedures for all types of welding processes of different</li>
                                            <li>Types of Materials.</li>
                                        </ul>
                                    </div>
                                </div>


                            </div>
                            <div className="col-12 col-md-6" >
                                <div
                                    className="card mb-3 card-color shadow"

                                    style={{ borderRadius: "5px" }}
                                >
                                    <div className="card-body">
                                        <h5 className="card-title">Offshore Fabrication Works</h5>
                                        <p className="card-text ">
                                            Midtech Marine Fabrication Yard owns the following facilities that will meet the offshore construction requirements:
                                        </p>
                                        <ul>
                                            <li>Berth facility of 220 meter long with 6.5 meter draft at low tide that allows for the berthing and execution of maintenance works of various types of marine vessels as well as jack ups overhauling works</li>
                                            <li>Load out piled concrete bay with dimensions 30 meter x 15 meter with capacity of 40 ton per square meter suitable for load out of heavy structures up to 9000 tons.</li>
                                            <li>Open area of 98415 m2 for assembly of all jacket and top sides’ large size structures with bearing capacity of 25 ton per square meter.</li>
                                            <li>Pile rack facility equipped with three numbers of submerged arc welding machines and self-ejecting hydraulic system suitable for welding and handling piles and jacket legs up to 100-meter length and up to 84-inch dia pipes with capacity up to 120-ton piles sections.</li>
                                            <li>Open storage area of 22000 m2 for storing all project structural material.</li>
                                            <li>Main workshop suitable for prefabrication works of individual members and other miscellaneous items for platform structures like Boat landing, riser guards, riser clamps, walkways stairs and handrails.</li>
                                            <li>Pipe profiling workshop is suitable for cutting and profiling of jacket and deck tubular braces up to 42-inch diameter suitable for piping prefabrication works.</li>
                                            <li>Blasting and Painting workshops.</li>
                                            <li>Warehouse facility with storage area of 900 m2 for storing all fittings, valves and other special tagged items.</li>
                                            <li>Lifting cranes facilities up to 400-ton capacity.</li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </di>
        </div>
    );
}

export default OffshoreServicesDivision;
