

import React from "react";
import { Link } from "react-router-dom";

function CivilandInfrastructureDivision() {
    return (
        <div className="">
            <di className="CivilandInfrastructureDivision">
                <div className="bg_image_1">
                    <div className="content">
                        <h3>Civil & Infrastructure Division</h3>
                    </div>

                </div>

                <section>
                    <div className="container py-5">

                        <div className="row justify-content-between py-5">
                            <h1 className="text-center pb-5">Civil & Infrastructure Division</h1>
                            <div className="col-12 col-md-6 ">
                                <div
                                    className="card mb-3 card-color shadow"

                                    style={{ borderRadius: "5px" }}
                                >
                                    <div className="card-body">
                                        <h5 className="card-title">Buildings Construction</h5>
                                        <p className="card-text ">
                                            Midtech’s long experience which goes back to 1975 in buildings construction and interior finishing works has allowed us to improve our perceptions as well as our understanding for the real purpose of quality and client satisfaction, therefore Midtech insists to provide the best services in all respects of building construction which encompasses:
                                        </p>
                                        <ul>
                                            <li>Commercial & Retail</li>
                                            <li>Hospitals & Health Center</li>
                                            <li>Residential</li>
                                            <li>Educations & Culture</li>
                                            <li>Industrial Facilities</li>
                                            <li>Government / Defense Buildings</li>
                                        </ul>
                                    </div>
                                </div>
                                <div
                                    className="card mb-3 card-color shadow"
                                    style={{ borderRadius: "5px" }}
                                >
                                    <div className="card-body">
                                        <h5 className="card-title ">Service Brochure</h5>
                                        <p className="card-text ">
                                            Midtech-Civil-Brochure.
                                        </p>
                                    </div>
                                </div>

                            </div>
                            <div className="col-12 col-md-6" >

                                <div
                                    className="card mb-3 card-color shadow"
                                    style={{ borderRadius: "5px" }}
                                >
                                    <div className="card-body">
                                        <h5 className="card-title ">Infrastructure & Civil Engineering</h5>
                                        <p className="card-text ">
                                        Midtech is a “Grade A” Classified Civil Contractor “ and “Grade 1” KAHRAMAA Licensed Electrical Contractor..
                                        </p>
                                        <p className="card-text ">
                                        Midtech’s infrastructure & Civil Engineering Division is offering the related services that include design, construction and maintenance for a wide range of civil construction works with a strong emphasis to offer the best services.
                                        </p>
                                        <ul>
                                            <li>Pipelines supporting and civil work</li>
                                            <li>Roads, Tunnels & Culverts Works</li>
                                            <li>Industrial plants</li>
                                            <li>Water reservoirs & Water structures</li>
                                            <li>Deep foundations & Trenches</li>
                                            <li>Water & Drainage networks</li>
                                            <li>Water & Sewage treatment plants</li>
                                            <li>Soft & Hard landscaping</li>
                                        </ul>
                                    </div>
                                </div>
                               

                            </div>
                        </div>
                    </div>
                </section>
            </di>
        </div>
    );
}

export default CivilandInfrastructureDivision;
