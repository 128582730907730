import React from "react";
import { Link } from "react-router-dom";
import ServicesDivisionData from "../data/ServicesDivisionData";
import { Button } from "@mui/material";

function ServicesDivision() {
  return (
    <div>
      <section className="our-business">
        <div className="bg_image_1">
          <div className="content">
            <h3>Services Division</h3>
          </div>
        </div>
      </section>

      <section className="our-business-us ">
        <div className="container py-5">
          <div className="">
            <div className="">
              <div className="inner">
                <p style={{ textAlign: "justify" }}>
                  Midtech Engineering and Construction Company has carefully structured its businesses into eight
                  verticals to focus on product and geography strategy. Our
                  two-way supply chain capabilities, coupled with on the ground
                  warehousing, logistics and distribution capabilities, makes
                  Midtech Engineering and Construction Company a global conglomerate.
                </p>
              </div>
            </div>

          </div>
        </div>
      </section>
      
      <section>
        <hr />
        <div className="container py-5">
          <h1 className="text-center">
            Services Division
          </h1>
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 ">
            {ServicesDivisionData.map((item) => {
              return (
                <div className="col py-4 ServicesDivisiondiv">
                  <div
                    className="card card-color mx-auto shadow p-2"
                  >
                    <img
                      src={item.src}
                      className="card-img-top"
                      alt="..."
                    />
                    <div className="card-body">
                      <h5 className="card-title">{item.heading}</h5>
                      <p className="card-text ">
                        {item.aboutInfo}
                      </p>
                      <Link to={`/${item.linkTag}`}> <Button>View Service Detail</Button></Link>
                    </div>
                  </div>
                </div>
              )
            })}



          </div>
        </div>
      </section>
    </div>
  );
}

export default ServicesDivision;
