import React from 'react'
import ImageGallery from './ImageGallery';
import card1 from '../assest/images/ourbusiness.jpg'
import card2 from '../assest/images/card2.jpg'
import card3 from '../assest/images/slider3.jpg'
import card4 from '../assest/images/slider4.jpg'
import card5 from '../assest/images/bg2.jpg'
import card6 from '../assest/images/buildtech.jpg'
import card7 from '../assest/images/construction.jpg'
import card8 from '../assest/images/first.jpg'
import card9 from '../assest/images/pexels-engin-akyurt-2036686.jpg'

import show1 from '../assest/images/show1.jpg'
import show2 from '../assest/images/show2.jpg'
import show3 from '../assest/images/show3.jpg'
import show4 from '../assest/images/show4.jpg'
import const1 from '../assest/images/const1.jpg'
import const2 from '../assest/images/const2.jpg'
import const3 from '../assest/images/const3.jpg'
import const4 from '../assest/images/const4.jpg'


function OurImageGallery() {
  const imageUrls = [card1, card2, card3, card4, card5, card6, card7, card8, card9];
  const showImg = [show1, show2, show3, show4]
  const constImg = [const1, const2, const3, const4]
  return (
    <>

      <div className='py-5'>
        <h1 className='text-center'>Construction</h1>
        <ImageGallery imageUrls={constImg} />
      </div>


      <div className=''>
        <h1 className='text-center'>Showroom</h1>
        <ImageGallery imageUrls={showImg} />
      </div>


      <div className=''>
        <h1 className='text-center'>Other</h1>
        <ImageGallery imageUrls={imageUrls} />
      </div>
    </>
  )
}

export default OurImageGallery
