
import people1 from '../assest/images/SingleIndustrialProjects.jpg'
import people2 from '../assest/images/Maintenance_Services.jpg'
import people3 from '../assest/images/MajorProjectsDivision.jpg'
import people4 from '../assest/images/SupportServicesDivision.jpg'
import people5 from '../assest/images/OffshoreServicesDivision.jpg'






const BannersData = [
    {
        id: 1,
        heading:'Elevate Your Style, Embrace Luxury – Our Showrooms Redefine Elegance, One Exquisite Display at a Time',
        src: people4,
    },
    {
        id: 2,
        heading:" Turning Dreams into Addresses – Elevate Your Life with Our Real Estate Expertise",
        src: people2,
    },
    {
        id: 3,
        heading:"Building Excellence, Crafting Futures – Your Vision, Our Construction",
        src: people3,
    },
    {
        id: 4,
        heading:"Global Excellence in Import-Export – Connecting Markets, Redefining Trade",
        src: people1,
    },
    {
        id: 5,
        heading:' Impex Innovations – Redefining Trade Dynamics with Global Excellence',
        src: people5,
    },
];
export default BannersData;
