import React, { useState } from 'react';
import Modal from 'react-modal';
import { FaWindowClose } from "react-icons/fa";


const VideoPlayerModal = ({ videoData }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);

  const openModal = (video) => {
    setCurrentVideo(video);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const isSmallScreen = window.innerWidth <= 600;

  return (
    <div>
      <div className='container-fluid'>
        <div className="gallery-container pb-4 py-5 d-flex flex-wrap justify-content-center">
          {videoData.map((video, index) => (
            <img key={index} src={video.poster} alt="Video Poster" onClick={() => openModal(video)} className="gallery-image" />
          ))}
        </div>
        {currentVideo && (
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Video Player Modal"
            style={{
              overlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.75)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
               
              },
              content: {
                position: 'relative',
                borderRadius: '10px',
                background: 'rgba(0, 0, 0, 0.75)',
                outline: 'none',
                padding: isSmallScreen ? '10px' : '20px',
                marginBottom: isSmallScreen ? '60px' : '50px',
                marginRight: isSmallScreen ? '80px' : 0,
               
              }

            }}
          >
            <div>
              <h3 className='text-light'>Selected Video</h3>
              <video controls autoPlay>
                <source src={currentVideo.video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <button onClick={closeModal} className="close-button">
                <FaWindowClose className=' fs-3' />
              </button>
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default VideoPlayerModal;
