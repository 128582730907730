
import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ThankYouDialoge from './ThankYouDialoge';

const office_Location_Google = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3037.6206184151633!2d49.906291374438425!3d40.41725455560981!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40306293ddda5cf9%3A0xa254f8c37d755e79!2s159%20Heyd%C9%99r%20%C6%8Fliyev%20prospekti%2C%20Qara%C3%A7uxur%2C%20Azerbaijan!5e0!3m2!1sen!2sin!4v1729336745024!5m2!1sen!2sin"
function Contact() {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };


  const [EnquiryData, SetEnquiryData] = useState({
    name: '',
    email: '',
    mobile: '',
    subject: '',
    message: '',
  })

  const [errorName, setErrorName] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorMobile, setErrorMobile] = useState("");
  const [bordrColor, setBordrColor] = useState("");

  const navigate = useNavigate()

  const onInputChnge = (e) => {
    const inpName = e.target.name;
    const inpVal = e.target.value;
    const cloned = { ...EnquiryData }
    cloned[inpName] = inpVal;
    SetEnquiryData(cloned);
  }


  const onSendClick = async (e) => {
    e.preventDefault();

    try {
      // Reset errors and color
      setErrorName("");
      setErrorEmail("");
      setErrorMobile("");
      setBordrColor("");

      // Perform input validation here before making the API call
      if (
        EnquiryData.name.length > 2 &&
        EnquiryData.email.includes("@") &&
        EnquiryData.mobile.length === 10 &&
        EnquiryData.subject.length > 2 &&
        EnquiryData.message.length > 2
      ) {
        setBordrColor('1px solid green');
        const sign_up = await axios.post(
          'https://backoffice.adamclick.com/api/auth/lead/register',
          { ...EnquiryData }
        );
        SetEnquiryData(sign_up.data);
        console.log(sign_up.data);

        // Clear the form fields
        SetEnquiryData({
          name: '',
          email: '',
          mobile: '',
          subject: '',
          message: '',
        });

        setOpen(true);
        // Provide user feedback or navigation here if needed
        // navigate('/contact')
        console.log('Form data submitted successfully!');
      } else {
        if (EnquiryData.name.length <= 2 || EnquiryData.name === '') setErrorName("enter name");
        if (!EnquiryData.email.includes("@") || EnquiryData.email === '') setErrorEmail("enter email");
        if (EnquiryData.mobile.length !== 10 || EnquiryData.mobile === '') setErrorMobile("enter valid 10 digit number");

        setBordrColor('1px solid white');
        // Provide user feedback for validation errors
        console.log('Form validation failed');
      }
    } catch (error) {
      // Provide user-friendly error message
      console.log('Error submitting form:', error.message);
    }
  };

  return (
    <>
      <div>
        <section className="contactus">
          <div className="bg_image_1">
            <div className="content">
              <h3> Contact Us</h3>
            </div>
          </div>
        </section>
        <section className="contact-us section1">
          <div className="">
            <div className="inner">
              <div className="row py-5">
                <div
                  className="col-lg-6 col-md-6 card-color shadow"
                  style={{ background: "", borderRadius: "5px" }}
                >
                  <div className="contact-us-form">
                    <h5 className="contact-paraa">
                      If you have any questions please fell free to contact with
                      us.
                    </h5>
                    <form className="form">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              type="text"
                              name="name"
                              onChange={onInputChnge}
                              value={EnquiryData.name}
                              style={{ border: bordrColor, borderRadius: '4px' }}
                              placeholder="Name"
                              required=""
                            />
                            <p style={{ color: 'red', fontSize: '12px' }} className="error">{errorName}</p>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              type="email"
                              name="email"
                              onChange={onInputChnge}
                              value={EnquiryData.email}
                              style={{ border: bordrColor, borderRadius: '4px' }}
                              placeholder="Email"
                              required=""
                            />
                            <p style={{ color: 'red', fontSize: '12px' }} className="error">{errorEmail}</p>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              type="text"
                              name="mobile"
                              onChange={onInputChnge}
                              value={EnquiryData.mobile}
                              style={{ border: bordrColor, borderRadius: '4px' }}
                              placeholder="Phone"
                              required=""
                            />
                            <p style={{ color: 'red', fontSize: '12px' }} className="error">{errorMobile}</p>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              type="text"
                              name="subject"
                              onChange={onInputChnge}
                              value={EnquiryData.subject}
                              placeholder="Subject"
                              required=""
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <textarea
                              name="message"
                              onChange={onInputChnge}
                              value={EnquiryData.message}
                              placeholder="Your Message"
                              required=""
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <button onClick={onSendClick} className="btn btn-primary" type="submit">
                              Send
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 py-5">
                  <div className="contact-us-left">
                    <div className="contact-info">
                      <i className="bx bx-home"></i>
                      <div className="contact-details">
                        <span className="contact-label">Head Office</span>
                        <p className="my-3">
                          159 Heydar Aliyev Ave, Baku 1011,
                          <br />
                          Azerbaijan
                        </p>


                      </div>
                    </div>
                    <div className="contact-info">
                      <i className="bx bx-envelope"></i>
                      <div className="contact-details">
                        <span className="contact-label">Email Address</span>
                        <p>info@midtechecc.com</p>
                      </div>
                    </div>
                    <div className="contact-info">
                      <i className="bx bx-phone"></i>
                      <div className="contact-details">
                        <span className="contact-label">Telephone</span>
                        <p> +994 12 533 63 63</p>
                      </div>
                    </div>
                    <div className="contact-info">
                      <i className="bx bx-time"></i>
                      <div className="contact-details">
                        <span className="contact-label">Office Hour</span>
                        <p>Mon-Sat: 10am – 7pm</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <iframe
                title="contact map"
                style={{ width: "100%", height: "400px", border: 0 }}
                src={office_Location_Google}
                allowFullScreen="" loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />

            </div>
          </div>
        </section>
      </div>

      <ThankYouDialoge open={open} setOpen={setOpen} handleClose={handleClose} />
    </>
  );
}

export default Contact;
