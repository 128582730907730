

import React from "react";
import { Link } from "react-router-dom";

function ElectricalInstrumentationDivision() {
    return (
        <div className="">
            <di className="ElectricalInstrumentationDivision">
                <div className="bg_image_1">
                    <div className="content">
                        <h3>Electrical & Instrumentation (E&I) Division</h3>
                    </div>

                </div>

                <section>
                    <div className="container py-5">

                        <div className="row justify-content-between py-5">
                            <h1 className="text-center pb-5">Electrical & Instrumentation (E&I) Division</h1>
                            <div className="col-12 col-md-6 ">

                                <div
                                    className="card mb-3 card-color shadow"
                                    style={{ borderRadius: "5px" }}
                                >
                                    <div className="card-body">
                                        <h5 className="card-title ">E&I Department Functions</h5>
                                        <p className="card-text ">
                                            Midtech Engineering and Construction Company is the leading National EPC & Maintenance Contractor in the Oil & Gas, Petrochemical, Fertilizer and Power industries.
                                        </p>
                                        <p className="card-text ">
                                            Midtech’s E&I Department provides total solutions from Engineering to Commissioning   to its clients specifically to Oil & Gas industries both onshore and offshore facilities. Midtech insists to provide the best services in Electrical and Instrumentation taking Safety, Quality and Client’s satisfaction as top priorities. Our focuses are in the following areas:
                                        </p>

                                        <ul>
                                            <li>EPIC (E&I).</li>
                                            <li>Support Major/Minor EPIC.</li>
                                            <li>Substation Construction and Commissioning.</li>
                                            <li>All kinds of Electrical and Instrumentation Testing.</li>
                                            <li>Oil and Gas plant turnaround support and specialized services.</li>
                                            <li>Electrical Testing of all kinds of Electrical Equipment as per Client’s requirements.</li>
                                            <li>Supply of test equipment and support to our Clients.</li>
                                            <li>Maintenance Services for Electrical and Instrument Equipment.</li>
                                            <li>Installation, testing, calibration, loop checking and commissioning of Instrument & Telecom equipment.</li>
                                            <li>Field Instrument installation and calibration.</li>
                                            <li>Cable and its support structure installation.</li>
                                            <li>DCS / SCADA/ ESD/ F&G Systems Installation, Modification & Commissioning.</li>
                                            <li>Installation and Maintenance of Hazardous area classified equipment.</li>
                                        </ul>
                                    </div>

                                </div>


                            </div>
                            <div className="col-12 col-md-6" >
                                <div
                                    className="card mb-3 card-color shadow"
                                    style={{ borderRadius: "5px" }}
                                >
                                    <div className="card-body">
                                        {/* <h5 className="card-title ">Offshorojects</h5> */}
                                        <p className="card-text ">
                                            E&I Department certified as Grade “1” Kahramaa License and service provider in the field of Electrical & Instrumentation for testing & Commissioning, Engineering, Erection Supervision and Maintenance of Power Plants, Oil & Gas and Petrochemical Plants. Midtech supplies specialized maintenance personnel for Electrical HV/MV/ LV Substations, Reliability Technicians, Motor Technicians, Field & Lab Instrumentation technicians, Analyzers Technicians, Metering specialist, F&G Technicians. We have around 45% (180 personnel) of our team supplied as skilled maintenance Technicians for around 11 Oil & Gas Operating Companies throughout 16 contracts as baseload permanent supply. We have earned good repute in this industry due to our highly reliable as well as effectual services.
                                        </p>
                                    </div>
                                </div>

                                <div
                                    className="card mb-3 card-color shadow"
                                    style={{ borderRadius: "5px" }}
                                >
                                    <div className="card-body">
                                        <h5 className="card-title ">Service Brochure</h5>
                                        <p className="card-text ">
                                            Midtech E&I Brochure
                                        </p>
                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </di>
        </div>
    );
}

export default ElectricalInstrumentationDivision;
