


import React from "react";
import { Link } from "react-router-dom";

function SupportServicesDivision() {
    return (
        <div className="">
            <di className="SupportServicesDivision">
                <div className="bg_image_1">
                    <div className="content">
                        <h3>Support Services Division</h3>
                    </div>

                </div>

                <section>
                    <div className="container py-5">

                        <div className="row justify-content-between py-5">
                            <h1 className="text-center pb-5">Support Services Division</h1>
                            <div className="col-12 col-md-6 ">
                                <div
                                    className="card mb-3 card-color shadow"

                                    style={{ borderRadius: "5px" }}
                                >
                                    <div className="card-body">
                                        <h5 className="card-title">Plant and Machinery</h5>
                                        <p className="card-text ">
                                            Midtech has an extensive fleet of plant and equipment, which includes cranes up to 300 tons capacity (and access to larger ones), low bed trailers up to 80 ton capacity, earth moving equipment such as, but not limited to, Excavators, Wheel loaders, Dozers, Graders, Concrete mixers, Welding machines, Compressors, Generators, Light towers and others. All equipment are owned, operated and maintained by fully qualified, highly experienced personnel enabling us to consistently meet all of customers and continually upholding the national certification requirements.
                                        </p>
                                        <p className="card-text ">
                                            P&M Provides services to the Midtech projects and Azerbaijan market at large by offering a wide variety of specialized and in excellent condition equipment and vehicles along with qualified and competent personnel.
                                        </p>
                                        <p className=" fw-bold">Services:</p>
                                        <ul>
                                            <li>Rental of Equipment (Mobile, stationary) and Vehicles.</li>
                                            <li>Lifting and Rigging design solutions.</li>
                                            <li>Manpower Supply (e.g. Equipment operators and multi skilled drivers).</li>
                                            <li>Equipment/Vehicles services (Maintenance & Repair).</li>
                                            <li>Transportation Services.</li>
                                        </ul>
                                    </div>
                                </div>








                                <div
                                    className="card mb-3 card-color shadow"
                                    style={{ borderRadius: "5px" }}
                                >
                                    <div className="card-body">
                                        <h5 className="card-title ">Scaffolding</h5>
                                        <p className="card-text ">
                                            Scaffolding Department has played a major role in advancing Midtech Business through its capability to cater for and cover all the requirements and needs of all internal projects. This capability has been extended to our external clients as a reliable scaffolding services provider.
                                        </p>
                                        <p className="card-text ">
                                            Today we have more than 250,000 Cubic meter of certified scaffolding materials distributed between our two logistic yards (QMFY, which covers Mesaieed area and Al Khor Yard which covers Ras Laffan area), along with 300 qualified scaffolding and CISRS qualified scaffolding inspectors and supervisors who are ready to execute their scaffolding services safely with full compliance with quality standards and requirements.
                                        </p>
                                        <p className="card-text ">
                                            We pride ourselves in delivering the scaffolding and access solutions for all of our clients across a wide range of scaffolding needs. (Global work, Consulting, manpower supply, material hiring); as well as providing efficient planning and management of major scaffolding projects with complex requirements supported by professional design, calculations and drawings.
                                        </p>
                                    </div>
                                </div>

                                <div
                                    className="card mb-3 card-color shadow"
                                    style={{ borderRadius: "5px" }}
                                >
                                    <div className="card-body">
                                        <h5 className="card-title ">Coating Services</h5>
                                        <p className="card-text ">
                                            Midtech Coating & Insulation Department has played a major role in advancing Midtech Business through its capability to cater for and cover all the requirements and needs of all internal projects. This capability has been extended to our external clients as a reliable coating and insulation services provider.
                                        </p>
                                        <p className="card-text ">
                                            We currently have a comprehensive set of tools and equipment which covers all of our internal projects’ needs and beyond. Many of these equipment are also being lend out to the local market, as part of Midtech new business initiatives. All Coating and Insulations operations are managed through two centers located at our workshops in MIC & RLIC.
                                        </p>
                                        <p className="card-text ">
                                            We have over 100 Insulators and Painters along with certified Engineers and Inspectors.
                                        </p>
                                        <p className="card-text ">
                                            Midtech Coating & Insulation Department is able to offer integrated services throughout all phases of industrial assets’ development and or maintenance.
                                        </p>
                                    </div>
                                </div>

                                <div
                                    className="card mb-3 card-color shadow"
                                    style={{ borderRadius: "5px" }}
                                >
                                    <div className="card-body">
                                        <h5 className="card-title ">Service Brochure</h5>
                                        <p className="card-text ">
                                            Plant-and-Machinery-Catalog.PDF
                                        </p>
                                        <p className="card-text ">
                                            Midtech-Scaffolding-Brochure.PDF
                                        </p>
                                        <p className="card-text ">
                                            Painting-Catalog.PDF
                                        </p>

                                    </div>
                                </div>

                            </div>

                            <div className="col-12 col-md-6" >

                                <div
                                    className="card mb-3 card-color shadow"
                                    style={{ borderRadius: "5px" }}
                                >
                                    <div className="card-body">
                                        <h5 className="card-title ">Controlled Bolt Torqueing</h5>
                                        <p className="card-text ">
                                            Bolt Torqueing services are undertaken in full compliance with safety and quality requirements as proper tightening of piping flanged joints and clamps are critical tasks to prevent leaks in gaskets like all other parts and systems within the Oil & Gas Plants. You can rely on Midtech controlled Bolt Services for the following reasons:
                                        </p>

                                        <ul>
                                            <li>Having in-house capability to handle the hydraulic  and manual torqueing of bolting and flange management with ZERO leak record.</li>
                                            <li>35 plus high caliber third party certified torqueing technicians and supervisors are available.</li>
                                            <li>Services provided to a numerous companies with the local and regional Oil & Gas, Fertilizer, Refinery, Petrochemical’s sectors and operating companies.</li>
                                            <li>Possess well maintained 30 units of various hydraulic pumps, different sizes of RSL and square drives, manual torque wrenches, flange aligning tools, Hydraulic and manual flange spreaders and a good quantity of sockets of different sizes with valid TPI certificates.</li>
                                        </ul>
                                    </div>
                                </div>

                                <div
                                    className="card mb-3 card-color shadow"
                                    style={{ borderRadius: "5px" }}
                                >
                                    <div className="card-body">
                                        <h5 className="card-title ">Lifting & Rigging</h5>
                                        <p className="card-text ">
                                            Midtech Lifting & Rigging Services, like all other services at Midtech, are undertaken in a safe manner and in compliance with all relevant and applicable legal or other requirements. You can rely on Midtech Lifting and Rigging Services for the following reasons:
                                        </p>

                                        <ul>
                                            <li>Well experienced Rigging Engineers for assessment of Heavy lifts and preparation of Lifting plans in AutoCAD.</li>
                                            <li>Third Party certified Rigging team consisting of Rigging Supervisors and Riggers.</li>
                                            <li>Large resources of TPI certified & colour coded Lifting tools and tackles consisting of Spreader bars, Wire rope slings, Nylon Webbing slings, Hydraulic Jacks, Material baskets, Bow shackles, eye bolts and D Shackles.</li>
                                            <li>Ready to move Lifting tool container to meet emergency requirements of client.</li>
                                            <li>Long term association with specialized heavy lift contractors in GCC Region to cater to specialized needs of transporting and lifting of Heavy equipment and oversized consignments.</li>
                                        </ul>
                                    </div>
                                </div>

                                <div
                                    className="card mb-3 card-color shadow"
                                    style={{ borderRadius: "5px" }}
                                >
                                    <div className="card-body">
                                        <h5 className="card-title ">Hydro Jetting</h5>
                                        <p className="card-text ">
                                            Midtech prides itself in its reliable and safe Hydro Services and considers itself a dependable partner in a range of services such as:
                                        </p>

                                        <ul>
                                            <li>Heat Exchanger Maintenance and Retubing works</li>
                                            <li>High Pressure Jetting Services</li>
                                            <li>Tube Bundle Extraction works</li>
                                            <li>Controlled Bolt Torquing</li>
                                            <li>Cold Cutting, Beveling and In-situ machining works</li>
                                            <li>Hydrotesting</li>
                                        </ul>
                                    </div>
                                </div>


                                <div
                                    className="card mb-3 card-color shadow"
                                    style={{ borderRadius: "5px" }}
                                >
                                    <div className="card-body">
                                        <h5 className="card-title ">Material Procurement and Control</h5>
                                        <p className="card-text ">
                                            Midtech Procurement Department plays a vital role in improving our business objectives and effectively managing the following functions:
                                        </p>

                                        <ul>
                                            <li>Strategic sourcing & material management for Operational & EPC procurement.</li>
                                            <li>Initiating & achieving cost savings through latest negotiating tools & procedures.</li>
                                            <li>ERP enabled & driven procurement systems for better procedures & controls.</li>
                                            <li>Sub-contract management through effective terms & conditions.</li>
                                            <li>Provides active support for proposal for bidding as per client tender timelines.</li>
                                            <li>Efficient stores management with JIT concept with optimum inventory carrying cost.</li>
                                            <li>Proactive expediting & logistics control for timely deliverables.</li>
                                            <li>Generation of smart MIS reports as decision support system for forecasting & SWOT analysis.</li>
                                        </ul>
                                    </div>
                                </div>

                               

                            </div>

                        </div>
                    </div>
                </section>
            </di>
        </div>
    );
}

export default SupportServicesDivision;
