import project1 from '../assest/images/project1.jpg'
import project2 from '../assest/images/project2.webp'
import project3 from '../assest/images/project3.jpg'
import project4 from '../assest/images/project4.jpg'
import project5 from '../assest/images/project5.jpg'
import project6 from '../assest/images/project6.jpg'
import project7 from '../assest/images/project7.jpeg'
import project8 from '../assest/images/project8.jpeg'
import project9 from '../assest/images/project9.png'
import project10 from '../assest/images/project10.jpg'




const ProjectData = [
    {
        id: 1,
        heading: "EPIC for Integrated Gas Supply to Mesaieed Consumers (IGSMC)",
        src: project1,
    },
    {
        id: 2,
        heading: "EPC for Near Zero Liquid Discharge (NZLD) Project at RLOC",
        src: project2,
    },
    {
        id: 3,
        heading: "NFPS Offshore Project (EPCO)/ NFPS Pipeline Project (EPCL)",
        src: project3,
    },
    {
        id: 4,
        heading: "WHP-U08C Topside, Pipelines, Umbilical & Brownfield Modification at PS1K (PHASE 5-1B)",
        src: project4,
    },
    {
        id: 5,
        heading: "EPC for the Brownfield Tie-ins of RL Looping, RLSS and RGESS",
        src: project5,
    },
    {
        id: 6,
        heading: "EPC for WHP12N – Jacket Execution for NFPS, Project",
        src: project6,
    },
    {
        id: 7,
        heading: "Design and Build Digital Division Building",
        src: project7,
    },
    {
        id: 8,
        heading: "EPIC for Wellhead Jacket and Temporary Desk in ISND Field -WHJU08C",
        src: project8,
    },
    {
        id: 9,
        heading: "EPIC for New Sour Gas Compressor at NGL-3 Plant, Mesaieed",
        src: project9,
    },
    {
        id: 10,
        heading: "EPC of New Head Quarter and Facilities",
        src: project10,
    }
];
export default ProjectData;
