
import people2 from '../assest/images/slider3.jpg'
import people3 from '../assest/images/Maintenance_Services.jpg'
import people4 from '../assest/images/pexels-sevenstorm-juhaszimrus-439416.jpg'



const MaintenanceServicesData = [
    {
        id: 1,
        heading: 'Maintenance Shutdown',
        aboutInfo: 'Shutdown maintenance for oil, gas and petrochemical plant, including equipment exchange and overhaul, installation of new equipment, ties-ins, catalyst exchange. ',
        src: people4,
        

    },
    {
        id: 2,
        heading: " Long-term Maintenance Support",
        aboutInfo: 'Long-term maintenance support contractors for oil, gas, petrochemical and industrial plant, including emergency call-out services.',
        src: people2,
    },

    {
        id: 3,
        heading: "Rapid Response Team (RRT)",
        aboutInfo: 'Small and short notice projects in all work disciplines (Technical manpower services, Plant and Equipment hire, Scaffolding hire and erection service)',
        src: people3,
    }
];
export default MaintenanceServicesData;
