
import client1 from '../assest/images/ourclient1.png'
import client2 from '../assest/images/ourclient2.gif'
import client3 from '../assest/images/ourclient3.jpg'
import client4 from '../assest/images/ourclient4.jpg'
import client5 from '../assest/images/ourclient5.jpg'
import client6 from '../assest/images/ourclient6.gif'
import client7 from '../assest/images/ourclient7.jpg'


const OurClientData = [
    {
        id: 1,
        imgSrc: client1,
        // name: 'Chennai client'
    },
    {
        id: 2,
        imgSrc: client2,
        // name: 'Delhi client'
    },
    {
        id: 3,
        imgSrc: client3,
        // name: 'Kochi client'

    }, {
        id: 4,
        imgSrc: client4,
        // name: 'NHAI Projects'
    }, {
        id: 5,
        imgSrc: client5,
        // name: 'Navi Mumbai Airport'

    }
    , {
        id: 6,
        imgSrc: client6,
        // name: 'Chennai client'
    },
    {
        id: 7,
        imgSrc: client7,
        // name: 'Delhi client'
    }
];
export default OurClientData

