


import React from "react";
import { Link } from "react-router-dom";

function MajorProjectsDivision() {
    return (
        <div className="">
            <di className="MajorProjectsDivision">
                <div className="bg_image_1">
                    <div className="content">
                        <h3>Major Projects Division</h3>
                    </div>

                </div>

                <section>
                    <div className="container py-5">

                        <div className="row justify-content-between py-5">
                            <h1 className="text-center pb-5">Major Projects Division</h1>
                            <div className="col-12 col-md-6 ">
                                <div
                                    className="card mb-3 card-color shadow"

                                    style={{ borderRadius: "5px" }}
                                >
                                    <div className="card-body">
                                        <h5 className="card-title">EPIC Projects & General Construction</h5>
                                        <p className="card-text ">
                                        Midtech is an integrated multi-disciplined Onshore and Offshore EPC/ EPIC & general contractor that has delivered EPC/EPIC and General Construction projects for the National and Regional Markets. Midtech’s customer base includes Azerbaijan Petroleum, Azerbaijan gas, RasGas, Dolphin Energy, Shell Azerbaijan, QAFCO, QAFAC, QAPCO, Q-Chem, ADGAS, Takreer and several other PSUs and private players. This includes Pipelines, Tank Farms, and Storage Tank such as LNG Tanks for various Oil & Gas Processing Plants. Midtech is also experienced in providing total solutions for Waste Water Treatment Facilities and Power Plants, as well as comprehensive solutions to the Oil & Gas, Fertilizer, Refinery, Petrochemical’s sectors in the most challenging environments.
                                        </p>

                                    </div>
                                </div>
                    
                                <div
                                    className="card mb-3 card-color shadow"
                                    style={{ borderRadius: "5px" }}
                                >
                                    <div className="card-body">
                                        <h5 className="card-title ">Mechanical Fabrication and Erection Works</h5>
                                        <p className="card-text ">
                                        Midtech takes & supports large size mechanical fabrication & erection works offering comprehensive solutions to the Oil & Gas, Petroleum & Infrastructure Industries with full compliance to specified requirements and agreed Quality and HSE standards.
                                        </p>
                                    </div>
                                </div>

                                <div
                                    className="card mb-3 card-color shadow"
                                    style={{ borderRadius: "5px" }}
                                >
                                    <div className="card-body">
                                        <h5 className="card-title ">Service Brochure</h5>
                                        <p className="card-text ">
                                        Electrical-and-Instrumentation-Catalog.PDF
                                        </p>
                                    </div>
                                </div>

                            </div>
                        
                            <div className="col-12 col-md-6" >

                                <div
                                    className="card mb-3 card-color shadow"
                                    style={{ borderRadius: "5px" }}
                                >
                                    <div className="card-body">
                                        <h5 className="card-title ">Civil, Instrumentation & Electro Mechanical Projects
                                        </h5>
                                        <p className="card-text ">
                                        Midtech is a “Grade A” Classified Civil Contractor and a “Grade 1” KAHRAMAA Licensed Electrical Contractor where Energy Sectors can assertively rely on us to carry out all their EPC/PC/C of civil, electrical & Instrumentation works. Midtech offers comprehensive and integrated Engineering, Procurement, Construction and Commissioning solutions serving clients in the Power, Oil & Gas, Fertilizer, Refinery, Petrochemical’s sectors in Azerbaijan & Regionally.
                                        </p>
                                       
                                    </div>
                                </div>

                                <div
                                    className="card mb-3 card-color shadow"
                                    style={{ borderRadius: "5px" }}
                                >
                                    <div className="card-body">
                                        <h5 className="card-title ">Engineering</h5>
                                        <p className="card-text ">
                                        Midtech offers multidisciplinary and detailed Design Engineering support to all our EPC/EPIC projects. Midtech Engineering group staffed with best in class and highly competent and experienced various engineering disciplines Engineers ranging from Process, Loss Prevention, Piping/Pipelines, Electrical, Instrumentation and Mechanical to HVAC, Structural and Civil Engineers.
                                        </p>
                                        <p className="card-text ">Midtech is having tailor made collaboration on a project-by-project basis with World-Class consulting firms such as Technip, WorleyParsons, and Ramboll. The Interface Management of such collaborations is controlled by our Engineering Department in compliance with agreed clients’ requirements and expectations.</p>
                                      
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </section>
            </di>
        </div>
    );
}

export default MajorProjectsDivision;
