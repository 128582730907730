import React, { useEffect } from "react";
// import ImageGallery from "./ImageGallery";
// import VideoPlayerModal from "./VideoPlayerModal";
import { Link } from "react-router-dom";
import '../style/homeStyle.css'
import AOS from "aos";
import "aos/dist/aos.css";
import Testimonial from "./Testimonial";
import { BsBuildings } from "react-icons/bs";
import { HiOutlineBuildingOffice2, HiOutlineTruck } from "react-icons/hi2";
import { MdOutlineRealEstateAgent } from "react-icons/md";
import { IoMdGlobe } from "react-icons/io";
import { FaCode } from "react-icons/fa";
import ImageSlider from "./ImageSlider";
import img2 from '../assest/images/card2.jpg'
import slider1 from '../assest/images/slider1.jpg'
import slider2 from '../assest/images/slider2.jpg'
import slider3 from '../assest/images/slider3.jpg'
import slider4 from '../assest/images/slider4.jpg'
import slider5 from '../assest/images/slider5.jpg'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Box, Button } from "@mui/material";
import BannersData from "../data/BannerData";
import styled from "styled-components";
import ServicesDivisionData from "../data/ServicesDivisionData";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import OurClientData from "../data/OurClientData";
import CertificateData from "../data/CertificateData";
import contactimgus from '../assest/images/contact-us-banner.jpg'
import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ThankYouDialoge from "./ThankYouDialoge";


const MyTitleMessage = styled.h1`
  position: absolute;
  width: 99.5%;
  top: 20rem;
  z-index: 1;
  margin-top: -125px;
  text-align: center;
  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4),0px 2px 5px rgba(0, 0, 0, 0.4);

  strong {
    font-size: 1.25em;
    font-weight: bold;
    letter-spacing: 2px;

  }
  div {
    color: white;
    text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4),0px 2px 5px rgba(0, 0, 0, 0.4);
    font-weight: 100;
    .main {
      font-size: 30px;
      margin: 0 10%;
    }
    .main p{
      font-size: 24px;
       margin: 20px 20% 0 20%;

    }
  }
  @media (min-width: 768px) and (max-width: 992px) {
    top: 18rem;
    div {
      .main {
        font-size: 40px;
      }
    }
  }

  @media (max-width: 767px) {
    top: 12rem;
    width: 98%;
    div {
      .main {
        font-size: 18px;
      }
      .main p{
      font-size: 14px;
       margin: 20px;

    }
    }
  }
`;

function Home() {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const [EnquiryData, SetEnquiryData] = useState({
    name: '',
    email: '',
    mobile: '',
    subject: '',
    message: '',
  })

  const [errorName, setErrorName] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorMobile, setErrorMobile] = useState("");
  const [bordrColor, setBordrColor] = useState("");

  const navigate = useNavigate()

  const onInputChnge = (e) => {
    const inpName = e.target.name;
    const inpVal = e.target.value;
    const cloned = { ...EnquiryData }
    cloned[inpName] = inpVal;
    SetEnquiryData(cloned);
  }


  const onSendClick = async (e) => {
    e.preventDefault();

    try {
      // Reset errors and color
      setErrorName("");
      setErrorEmail("");
      setErrorMobile("");
      setBordrColor("");

      // Perform input validation here before making the API call
      if (
        EnquiryData.name.length > 2 &&
        EnquiryData.email.includes("@") &&
        EnquiryData.mobile.length === 10 &&
        EnquiryData.subject.length > 2 &&
        EnquiryData.message.length > 2
      ) {
        setBordrColor('1px solid green');
        const sign_up = await axios.post(
          'https://backoffice.adamclick.com/api/auth/lead/register',
          { ...EnquiryData }
        );
        SetEnquiryData(sign_up.data);
        console.log(sign_up.data);

        // Clear the form fields
        SetEnquiryData({
          name: '',
          email: '',
          mobile: '',
          subject: '',
          message: '',
        });
        setOpen(true);

        // Provide user feedback or navigation here if needed
        // navigate('/')
        console.log('Form data submitted successfully!');
      } else {
        if (EnquiryData.name.length <= 2 || EnquiryData.name === '') setErrorName("enter name");
        if (!EnquiryData.email.includes("@") || EnquiryData.email === '') setErrorEmail("enter email");
        if (EnquiryData.mobile.length !== 10 || EnquiryData.mobile === '') setErrorMobile("enter valid 10 digit number");

        setBordrColor('1px solid white');
        // Provide user feedback for validation errors
        console.log('Form validation failed');
      }
    } catch (error) {
      // Provide user-friendly error message
      console.log('Error submitting form:', error.message);
    }
  };

  // const videoSource = 'src/video/video.mp4';
  useEffect(() => {
    AOS.init({ duration: "1500", delay: "100" });
  }, []);


  const settings = {
    dots: false,
    infinite: true,
    speed: 700,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: '1px',
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 4,
          centerMode: true,
          centerPadding: '1px',
        },
      },
      {
        breakpoint: 9999, // A large value to ensure the last breakpoint always applies
        settings: {
          slidesToShow: 4,
        },
      }
    ],
  };



  return (
    <div className="">
      <div className="home">
        <div className="bg_image_1">
          <ul data-aos="fade-up">
            <li>
              <Link to="/maintenance_services_division">Maintenance Services Division</Link>
            </li>
            <li>
              <Link to="/civil_infrastructure_services_division">Civil and Infrastructure Division</Link>
            </li>
            <li>
              <Link to="/major_projects_division">Major Projects Division</Link>{" "}
            </li>
            <li>
              <Link to="/support_services_division">Support Services Division</Link>{" "}
            </li>

          </ul>
          <div className="background-container" data-aos="fade-up">
            <div className="overlay-container p-2">
              <h1
                className="animated bounceInDown "
                style={{ animationDelay: ".3s" }}
              >
                Midtech Engineering and Construction Company
              </h1>
              <br />
              {/* <p
                className="animated bounceInDown"
                style={{ animationDelay: ".2s" }}
              >
                With expertise spanning import-export, real estate, enterprise
                logistics, and beyond, our group of companies is a powerhouse of
                innovation and opportunity.
              </p> */}
            </div>
          </div>
        </div>

        <section>
          <div className="container py-5">
            <h2
              className="py-5"
              style={{ color: "#344e41" }}
              data-aos="fade-up"
            >
              About Midtech Engineering and Construction Company
              <hr style={{ padding: "2px", width: "88%" }} />
            </h2>
            <p style={{ textAlign: "justify", fontSize: '20px' }} data-aos="fade-up">
              Midtech Engineering and Construction Company is the leading National EPC & Maintenance Contractor in the Oil & Gas, Petrochemical, Fertilizer and Power industries.
            </p>{" "}
            <strong style={{ textAlign: "justify", fontSize: '20px' }} data-aos="fade-up">
              To focus on the specific needs of its clients, Qcon operates through its three divisions:
            </strong>
            <ul className=" my-2" style={{ textAlign: "justify", fontSize: '20px' }}>
              <li>Industrial Projects.</li>
              <li>Maintenance Services.</li>
              <li>Mechanical Fabrication.</li>
            </ul>

            <p style={{ textAlign: "justify", fontSize: '20px' }} data-aos="fade-up">
              Midtech is a multi-discipline industrial Engineering & Construction Company, capable of delivering total solutions to its clients in the Oil & Gas industries.
            </p>
            <p style={{ textAlign: "justify", fontSize: '20px' }} data-aos="fade-up">
              <br />
              Formally known as Midtech, the company was established in 1975. Since then, Midtech has contributed immensely to the hydrocarbon infrastructural development in Azerbaijan.
            </p>

          </div>
        </section>

        <section>
          <hr />
          <div className="container py-5">
            <h1 className="text-center" data-aos="fade-up">
              Services Division
            </h1>
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 ">
              {ServicesDivisionData.map((item) => {
                return (
                  <div className="col py-4 ServicesDivisiondiv">
                    <div
                      className="card card-color mx-auto shadow p-2"
                      data-aos="fade-up"
                    >
                      <img
                        src={item.src}
                        className="card-img-top"
                        alt="..."
                      />
                      <div className="card-body">
                        <h5 className="card-title">{item.heading}</h5>
                        <p className="card-text ">
                          {item.aboutInfo}
                        </p>
                        <Link to={`/${item.linkTag}`}><Button>View Service Detail</Button></Link>
                      </div>
                    </div>
                  </div>
                )
              })}



            </div>
          </div>
        </section>

        {/* <section className="service" id="service">
          <h2
            className="heading"
            style={{ color: "#344e41", fontSize: "40px" }}
            data-aos="fade-up"
          >
            Our Group
            <hr style={{ padding: "2px", width: "11.5%" }} />
          </h2>

          <div className="service-container">
            <div className="service-box" data-aos="fade-up">
              <h1 className="px-4">
                <BsBuildings color="white" />
              </h1>
              <h4> UV Construction</h4>
              <p>
                Utilizing advanced technologies, we discover and extract
                petroleum resources to meet global energy demands.
              </p>
            </div>

            <div className="service-box" data-aos="fade-up">
              <h1 className="px-4">
                <HiOutlineBuildingOffice2 color="white" />
              </h1>
              <h4> Midtech Enterprises</h4>
              <p>
                Facilitating the buying and selling of gold & Diamond, ensuring
                secure and transparent transactions in the global market.
                <br />
              </p>
            </div>

            <div className="service-box" data-aos="fade-up">
              <h1 className="px-4">
                <IoMdGlobe color="white" />
              </h1>
              <h4>Midtech Implex Co.</h4>
              <p>
                Facilitating smooth imports and exports across borders,
                connecting businesses to diverse Group markets.
              </p>
            </div>
            <div className="service-box" data-aos="fade-up">
              <h1 className="px-4">
                <HiOutlineTruck color="white" />
              </h1>
              <h4>Midtech Freight & Logistic Co.</h4>
              <p>
                Facilitating the buying and selling of gold & Diamond, ensuring
                secure and transparent transactions in the global market.
              </p>
            </div>
            <div className="service-box" data-aos="fade-up">
              <h1 className="px-4">
                <MdOutlineRealEstateAgent color="white" />
              </h1>
              <h4> Midtech Buildtech Co.</h4>
              <p>
                Facilitating the buying and selling of gold & Diamond, ensuring
                secure and transparent transactions in the global market.
              </p>
            </div>
            <div className="service-box" data-aos="fade-up">
              <h1 className="px-4">
                <FaCode color="white" />
              </h1>
              <h4> Midtech Software Co.</h4>
              <p>
                Elevate Your Style, Embrace Luxury – Our Showrooms Redefine
                Elegance, One Exquisite Display at a Time
              </p>
            </div>
          </div>
        </section> */}

        <section>
          <hr />
          <div className="py-5" data-aos="fade-up">
            <h1 className="text-center" data-aos="fade-up">
              IMAGE GALLERY
            </h1>
            <ImageSlider />
          </div>
          <div class="d-flex justify-content-center pb-5" data-aos="fade-up">
            <button type="button" class="btn btn-dark">
              <Link
                to="/imagegallery"
                style={{ textDecoration: "none", color: "white" }}
              >
                View More
              </Link>
            </button>
          </div>
          <hr />
          <div className="py-5">
            <Testimonial />
          </div>
        </section>

        <Box id="BoxId">
          <Swiper
            centeredSlides={true}
            spaceBetween={0}
            slidesPerView={1}
            pagination={{ clickable: true }}
            navigation={{ clickable: true }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Pagination, Navigation]}
            className=""
          >
            {BannersData?.map(({ id, heading, src }) => {
              return (
                <SwiperSlide key={id} className="">
                  <div className="custom-img">
                    <img src={src} alt="img" />
                    <MyTitleMessage>
                      <div className="titleMessage">
                        <div className="heading">
                          <div className="main text-center mb-3">
                            <span>
                              <strong style={{ textTransform: 'uppercase' }}>{heading}</strong>
                            </span>
                          </div>

                        </div>
                      </div>
                    </MyTitleMessage>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Box>

        <Box className='logistic_clients'>
          <h1>OUR CLIENTS</h1>
          <div className="our_partner">
            <Slider {...settings}>
              {OurClientData?.map((item, index) => (
                <div key={item.id} className="Projects_topimg">
                  <img src={item.imgSrc} alt={`img-${item.id}`} />
                </div>
              ))}
            </Slider>
          </div>
        </Box>

        <Box className='logistic_clients'>
          <h1>Certificates</h1>
          <div className="our_partner">
            <Slider {...settings}>
              {CertificateData?.map((item, index) => (
                <div key={item.id} className="Projects_topimg">
                  <img src={item.imgSrc} alt={`img-${item.id}`} />
                </div>
              ))}
            </Slider>
          </div>
        </Box>

        <section className="contact-us section1">
          <h1 className=" text-center pt-3">Contact Us</h1>
          <div className="">
            <div className="inner">
              <div className="row justify-content-between align-items-start py-5">
                <div className="col-lg-6 col-md-6 p-0">
                  <div className="contact-us-left">
                    <img src={contactimgus} alt="img" />
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 card-color shadow"
                  style={{ background: "", borderRadius: "0 4px 4px 0" }}
                >
                  <div className="contact-us-form">
                    <h5 className="contact-paraa">
                      If you have any questions please fell free to contact with
                      us.
                    </h5>
                    <form className="form">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              type="text"
                              name="name"
                              onChange={onInputChnge}
                              value={EnquiryData.name}
                              style={{ border: bordrColor, borderRadius: '4px' }}
                              placeholder="Name"
                              required=""
                            />
                            <p style={{ color: 'red', fontSize: '12px' }} className="m-0">{errorName}</p>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              type="email"
                              name="email"
                              onChange={onInputChnge}
                              value={EnquiryData.email}
                              style={{ border: bordrColor, borderRadius: '4px' }}
                              placeholder="Email"
                              required=""
                            />
                            <p style={{ color: 'red', fontSize: '12px' }} className="m-0">{errorEmail}</p>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              type="text"
                              name="mobile"
                              onChange={onInputChnge}
                              value={EnquiryData.mobile}
                              style={{ border: bordrColor, borderRadius: '4px' }}
                              placeholder="Phone"
                              required=""
                            />
                            <p style={{ color: 'red', fontSize: '12px' }} className="m-0">{errorMobile}</p>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              type="text"
                              name="subject"
                              onChange={onInputChnge}
                              value={EnquiryData.subject}
                              placeholder="Subject"
                              required=""
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <textarea
                              name="message"
                              onChange={onInputChnge}
                              value={EnquiryData.message}
                              placeholder="Your Message"
                              required=""
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <button onClick={onSendClick} className="btn btn-primary" type="submit">
                              Send
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>

        <ThankYouDialoge open={open} setOpen={setOpen} handleClose={handleClose} />


      </div>
    </div>
  );
}

export default Home;
