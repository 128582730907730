import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Footer from './components/Footer';
import AboutUs from './components/AboutUs';
import Teams from "./components/Teams";
import Career from "./components/Career";
import Blogs from "./components/Blogs";
import Contact from "./components/Contact";
import OurImageGallery from "./components/OurImageGallery";
import UvConstruction from "./components/UvConstruction";
import AahiEnterprises from "./components/ErrorPage";
import AahiImplexCompany from "./components/AahiImplexCompany";
import FreightLogistic from "./components/FreightLogistic";
import AahiBuildTech from "./components/AahiBuildTech";
import OurVideoGallery from "./components/OurVideoGallery";
import ImageSlider from "./components/ImageSlider";
import ServicesDivision from "./components/ServicesDivision";
import MaintenanceServicesDivision from "./components/MaintenanceServicesDivision";
import CivilandInfrastructureDivision from "./components/CivilandInfrastructureDivision";
import MajorProjectsDivision from "./components/MajorProjectsDivision";
import SupportServicesDivision from "./components/SupportServicesDivision";
import OffshoreServicesDivision from "./components/OffshoreServicesDivision";
import ElectricalInstrumentationDivision from "./components/ElectricalInstrumentationDivision";
import Projects from "./components/Projects";
import ErrorPage from "./components/ErrorPage";

function App() {
  return (
    <Router>
      <Navbar />
      {/* <div className=""style={{marginTop:"100px"}}> */}

      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/career" element={<Career />} />
        <Route exact path="/blogs" element={<Blogs />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/imagegallery" element={<OurImageGallery />} />
        <Route exact path="/about" element={<AboutUs />} />
        <Route exact path="/ourpeople" element={<Teams />} />
        <Route exact path="/videogallery" element={<OurVideoGallery />} />
        <Route exact path="/services_division" element={<ServicesDivision />} />
        <Route exact path="/maintenance_services_division" element={<MaintenanceServicesDivision />} />
        <Route exact path="/civil_infrastructure_services_division" element={<CivilandInfrastructureDivision />} />
        <Route exact path="/major_projects_division" element={<MajorProjectsDivision />} />
        <Route exact path="/support_services_division" element={<SupportServicesDivision />} />
        <Route exact path="/offshore_services_division" element={<OffshoreServicesDivision />} />
        <Route exact path="/electrical_instrumentation_division" element={<ElectricalInstrumentationDivision />} />
        <Route exact path="/projects" element={<Projects />} />


        {/* <Route exact path="/construction" element={<UvConstruction />} />
        <Route exact path="/implex" element={<AahiImplexCompany />} />
        <Route exact path="/logistic" element={<FreightLogistic />} />
        <Route exact path="/buildtech" element={<AahiBuildTech />} /> */}
        {/* <Route exact path="/imageslider" element={<ImageSlider />} />  */}

        <Route exact path="*" element={<ErrorPage />} />

      </Routes>
      <Footer />
      {/* </div> */}
    </Router>
  );
}

export default App;
