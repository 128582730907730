
import React from "react";
import { Link } from "react-router-dom";

function MaintenanceServicesDivision() {
    return (
        <div className="">
            <di className="construction">
                <div className="bg_image_1">
                    <div className="content">
                        <h3>Maintenance Services Division</h3>
                    </div>

                </div>

                <section>
                    <div className="container py-5">

                        <div className="row justify-content-between py-5">
                            <h1 className="text-center pb-5">Maintenance Services Division</h1>
                            <div className="col-12 col-md-6 ">
                                <div
                                    className="card mb-3 card-color shadow"

                                    style={{ borderRadius: "5px" }}
                                >
                                    <div className="card-body">
                                        <h5 className="card-title">Consolidated Maintenance Contracts</h5>
                                        <p className="card-text ">
                                            Provision of Plant Maintenance Services that involves static & rotating equipment maintenance, repair & modification of piping & structural components, rigging & lifting services, inspection and Third Party and Vendor Services.
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="card mb-3 card-color shadow"
                                    style={{ borderRadius: "5px" }}
                                >
                                    <div className="card-body">
                                        <h5 className="card-title ">Maintenance Shutdown Projects (Onshore & Offshore)</h5>
                                        <p className="card-text ">
                                            Planning & execution of Major Turnarounds and Planned/Emergency shutdowns to take care of the Statutory Inspection requirements and repair works of equipment and piping on Onshore and Offshore Plant facilities. Capable to execute up to three Major Shutdowns (2000+ persons/shutdown) simultaneously.
                                        </p>

                                    </div>
                                </div>

                                <div
                                    className="card mb-3 card-color shadow"

                                    style={{ borderRadius: "5px" }}
                                >
                                    <div className="card-body">
                                        <h5 className="card-title ">Technical Manpower supply</h5>
                                        <p className="card-text ">
                                            Provision of Long Term Maintenance Manpower (classified as Baseload Manpower or Full Time Employee or Defined Estimate Manpower by clients). The Categories include but not limited to Skilled Maintenance Technicians in Mechanical, Electrical, Instrumentation, Fire & Gas, Analyzer, Welding, Fabrication, Rigging, Automobile, Overhead Crane, Machining, Warehouse, and Valve servicing.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6" >

                                <div
                                    className="card mb-3 card-color shadow"
                                    style={{ borderRadius: "5px" }}
                                >
                                    <div className="card-body">
                                        <h5 className="card-title ">Rapid Response Team</h5>
                                        <p className="card-text ">
                                            A strong and diversified team of Multi-skilled personnel who can cater for and support any emergency requirements in the Oil & Gas and Petrochemical Plants. The team is ready to mobilize on short notices 24/7 with valid Industrial City Gate passes and HSE induction of various clients.
                                        </p>
                                    </div>
                                </div>

                                <div
                                    className="card mb-3 card-color shadow"
                                    style={{ borderRadius: "5px" }}
                                >
                                    <div className="card-body">
                                        <h5 className="card-title ">Fabrication of Pressure Vessels & Process Packages</h5>
                                        <p className="card-text ">
                                            ASME certified facility to carryout fabrication of Pressure Vessels in accordance to ASME Section VIII Division 1&2. We hold ASME U, U2, PP, S & R Stamps
                                        </p>
                                        <p>Authorized to carry out Vessel Repair works as per NBBI (National Board of Boiler and Pressure Vessel Inspectors)</p>
                                    </div>
                                </div>

                                <div
                                    className="card card-color shadow"
                                    style={{ borderRadius: "5px" }}
                                >
                                    <div className="card-body">
                                        <h5 className="card-title ">Brownfield Projects</h5>
                                        <p className="card-text ">
                                            Solid team of Project Managers and Engineers who are well experienced in Brownfield Projects within facilities involving SIMOPS, Live plant PTW’s and well planned Tie-in/Hook up and Commissioning activities.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </di>
        </div>
    );
}

export default MaintenanceServicesDivision;
