import React from "react";
import profile3 from '../assest/images/profile3.jpg'

function Teams() {
  return (
    <div className="team">
      <section className="team">
        <div className="bg_image_1">
          <div className="content">
            <h3> Our Team</h3>
          </div>
        </div>
      </section>
      <p style={{ padding: "30px", textAlign: "justify" }}>
        We aspire to build a work culture that puts our people first. Nurturing
        them along their career-path, is committed to help our teams fulfil
        their career goals. Our ethos of equality, meritocracy, constant
        learning and focus on work-life balance ensures that our people realise
        their full potential.
      </p>

      
      <div className="container py-5">
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 g-md-5 px-5">
          <div className="col">
            <div
              className=" team-card card-color shadow"
            >
              <img
                src={profile3}
                className=""
                alt="..."
               
              />
              <h5 className=" text-center pt-2">Mohd Bilal</h5>
            </div>
          </div>
          <div className="col">
            <div className="card team-card card-color shadow" >
              <img
                src={profile3}
                className="card-img-top"
                alt="..."
              />
              <h5 className=" text-center pt-2">Mohd Bilal</h5>

            </div>
          </div>
          <div className="col">
            <div className="card team-card card-color shadow" >
              <img
                src={profile3}
                className="card-img-top"
                alt="..."
              />
              <h5 className=" text-center pt-2">Mohd Bilal</h5>
            </div>
          </div>

          <div className="col">
            <div className="card team-card card-color shadow" >
              <img
                src={profile3}
                className="card-img-top"
                alt="..."
              />
              <h5 className=" text-center pt-2">Mohd Bilal</h5>
            </div>
          </div>
          <div className="col">
            <div className="card team-card card-color shadow" >
              <img
                src={profile3}
                className="card-img-top"
                alt="..."
              />
              <h5 className=" text-center pt-2">Mohd Bilal</h5>
            </div>
          </div>
          <div className="col">
            <div className="card team-card card-color shadow" >
              <img
                src={profile3}
                className="card-img-top"
                alt="..."
              />
              <h5 className=" text-center pt-2">Mohd Bilal</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Teams;
