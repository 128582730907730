import React from 'react'
import VideoPlayerModal from './VideoPlayerModal'
import video1 from '../assest/videos/video1.mp4'
import video2 from '../assest/videos/video2.mp4'
import video3 from '../assest/videos/video3.mp4'
// import video4 from '../assest/videos/video.mp4'

function OurVideoGallery() {
    const videoData = [{
        poster: "https://images.pexels.com/photos/1116035/pexels-photo-1116035.jpeg?auto=compress&cs=tinysrgb&w=600",
        video: video1
      },{
        poster: "https://images.pexels.com/photos/209235/pexels-photo-209235.jpeg?auto=compress&cs=tinysrgb&w=600",
        video: video2,
      },
      {
        poster: "https://images.pexels.com/photos/159375/construction-site-build-construction-work-159375.jpeg?auto=compress&cs=tinysrgb&w=600",
        video: video3,
      },
      {
        poster: "https://images.pexels.com/photos/193791/pexels-photo-193791.jpeg?auto=compress&cs=tinysrgb&w=600",
        video: video1,
      },
    ];
  return (
    <div>
      <div className='py-5'>
        <h1 className='text-center'>OUR VIDEOS</h1>
      <VideoPlayerModal videoData={videoData} />
    </div>
    </div>
  )
}

export default OurVideoGallery
