import React, { useState } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './imageslider.css'
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import img1 from '../assest/images/ElectricalInstrumentationDivision.jpg'
import img2 from '../assest/images/Maintenance_Services.jpg'
import img3 from '../assest/images/MajorProjectsDivision.jpg'
import img4 from '../assest/images/OffshoreServicesDivision.jpg'
import img5 from '../assest/images/SingleIndustrialProjects.jpg'
import img6 from '../assest/images/SupportServicesDivision.jpg'
import img7 from '../assest/images/pexels-athena-3588036.jpg'
import img8 from '../assest/images/pexels-engin-akyurt-2036686.jpg'
import img9 from '../assest/images/ourbusiness.jpg'
import img10 from '../assest/images/pexels-sevenstorm-juhaszimrus-439416.jpg'

const ImageSlider = () => {

    const isSmallScreen = window.innerWidth <=600;

    const[slideIndex,setSlideIndex]=useState(0);

    const NextArrow = ({ onClick }) => {
        return (
          <div className="arrow next" onClick={onClick}>
            <FaArrowRight />
          </div>
        );
      };
      const PrevArrow = ({ onClick }) => {
        return (
          <div className="arrow prev" onClick={onClick}>
            <FaArrowLeft />
          </div>
        );
      };
    
      const settings = {
        dots:true,
        infinite: true,
        lazyload: true,
        speed: 300,
        slidesToShow:  isSmallScreen ? 1 : 3,
        slidesToScroll: 1,
        autoplay: true,
        centerMode: true,
        beforeChange:(current,next)=>setSlideIndex(next),
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
      };
      
      const image = [
        { img: img1 },
        { img: img2 },
        { img: img3 },
        { img: img4 },
        { img: img5 }, 
        { img: img6 },
        { img: img7 },
        { img: img8 },
        { img: img9 },
        { img: img10 },
      ];
  return (
    <div className='container pt-5'>
        <div className="img-slider">
            <Slider{...settings}>
                {
                    image.map((img,index)=>(
                        <div className={ index === slideIndex ? 'img-slide img-slide-active':'img-slide p-2'} key={index}>
                            <img src={img.img} alt="" />
                        </div>
                    ))
                }
            </Slider>
        </div>
    </div>
  )
}

export default ImageSlider
